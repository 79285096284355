import ListGroup from 'react-bootstrap/ListGroup';
import { CartesianGrid, ResponsiveContainer, 
    XAxis, YAxis, Area, Tooltip, Legend, Bar, ComposedChart, Line } from "recharts";
import Spinner from "../meteorologia/components/Spinner";
import '../meteorologia/styles/Card.css';
import './PlanillaVientos.css';

const vientoMinimo = 7.22 * 3.6;  // equivale a 26 km/h

const estiloTest = {
    backgroundColor : "white",
    fontSize: '12px',

}

const dirViento = {
    "N": ["Ferradurinha", "Foca", "Forno", "Geribá", "Tucuns"],
    "S": ["Azeda", "Azedinha", "Brava", "Canto", "João Fernandes", "João Fernandinho", "Manguinhos", "Ossos", "Tartaruga" ],
    "E": ["Azeda", "Azedinha", "Ferradura - Lado izquierdo", "Forno", "Geribá", "João Fernandes", "João Fernandinho", "Manguinhos", "Ossos", "Tartaruga"],
    "O": ["Brava", "Canto - Lado izquierdo", "Ferradura - Lado derecho", "Ferradurinha", "Foca ","Forno", "Geribá - Lado derecho", "Manguinhos - Lado izquierdo", "Ossos", "Pai Vitório", "Rasa", "Tartaruga - Lado izquierdo", "Tucuns"],
    "NE":["Ferradura", "Ferradurinha", "Foca", "Forno", "Geribá", "João Fernandinho", "Ossos", "Tucuns - Lado izquierdo"],
    "SE":["Azeda", "Azedinha", "Brava", "Canto", "Forno", "Geribá - Lado izquierdo", "João Fernandes", "João Fernandinho", "Manguinhos", "Ossos", "Tartaruga"],
    "SO":["Azeda","Brava", "Canto", "Ferradura - Lado derecho", "Ferradurinha", "Foca", "João Fernandes", "Manguinhos - Lado izquierdo", "Ossos", "Rasa", "Tartaruga - Lado izquierdo"],  
    "NO":["Brava", "Ferradura ", "Ferradurinha", "Foca ","Forno", "Geribá ", "Pai Vitório", "Tucuns"]
}

const semana = ["Dom-","Lun-", "Mar-", "Mier-","Jue-","Vie-","Sab-","Dom-","Lun-", "Mar-", "Mier-"];

const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    if (value > vientoMinimo) {
      return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />

        </svg>
      );
    }
  
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />

      </svg>
    );
  };

const direccion = (grados) => {

    if (grados >= 0 && grados < 22.5) {
        return "N"; 
      } else if (grados >= 22.5 && grados < 67.5) {
        return "NE"; 
      } else if (grados >= 67.5 && grados < 112.5) {
        return "E"; 
      } else if (grados >= 112.5 && grados <= 157.5) {
        return "SE"; 
      } else if (grados >= 157.5 && grados <= 202.5) {
        return "S"; 
      } else if (grados >= 202.5 && grados <= 247.5) {
        return "SO"; 
      } else if (grados >= 247.5 && grados <= 292.5) {
        return "O"; 
      } else if (grados >= 292.5 && grados <= 337.5) {
        return "NO"; 
      } else if (grados >= 337.5 && grados <= 360.5) {
        return "N"; 
      } else {
        return "Valor no válido"; 
      }

}


const PlanillaVientos = ({loadingData, showData, weather, forecast}) => {

if(loadingData){
    return <Spinner  />;
}
if(showData){

    var data = [];

    var direccionViento = weather.wind.deg;

    var puntoCardinal = direccion(direccionViento);
   

        //fecha actual
        var today = new Date();
        var diaActual = today.getUTCDay();
        var week = semana[diaActual];
        var day = today.getDate();
        var month = today.getMonth()+1;
        var year = today.getFullYear();
        var date = week + day + '/' + month + '/' +year;

        console.log("horario que se muestra con new Date() :", date)


        var timestamp1 = weather.dt;
        var fechaMilisegundos1 = timestamp1 * 1000;    
        var fecha1 = new Date(fechaMilisegundos1);
        /*
        var zonaHorariaLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var fechaLocal = new Date(fecha1.toLocaleString({ timeZone: zonaHorariaLocal }));
        var timestampLocal = fechaLocal.getTime();
        var fecha2 = new Date(timestampLocal);
        */

        console.log(`Timestamp original: ${timestamp1}`);
       // console.log(`Timestamp ajustado a la zona horaria local (${zonaHorariaLocal}): ${timestampLocal}`);


        console.log("direcion el viento prueba : ", dirViento);

    
        var horas2 = fecha1.getHours();
        var minutos1 = fecha1.getMinutes();

        console.log("datos de fecha local  :", fecha1 );


        var horario1 = `${horas2 < 10 ? '0' + horas2 : horas2}:${minutos1 < 10 ? '0' + minutos1 : minutos1}`;

        console.log("horario que se muestra desde la API  :", horario1 );


        var velviento = (weather.wind.speed * 3.6).toFixed(1);
        var velrafagas = (weather.wind.gust * 3.6).toFixed(1);

        data.push({hora: horario1, viento: velviento, rafagas: velrafagas})




    forecast.list.map((contenido, index) => {

        if(index<5){
        var timestamp = contenido.dt;
        var fechaMilisegundos = timestamp * 1000;    
        var fecha = new Date(fechaMilisegundos);
        /*
        var fechaLocal1 = new Date(fecha.toLocaleString({ timeZone: zonaHorariaLocal }));
        var timestampLocal1 = fechaLocal1.getTime();
        var fecha3 = new Date(timestampLocal1);
    
        console.log("datos de fecha local  :", fecha.getHours() );
        */

        var horas = fecha.getHours();
        var minutos = fecha.getMinutes();

        var horario = `${horas < 10 ? '0' + horas : horas}:${minutos < 10 ? '0' + minutos : minutos}`;
        var velviento2 = contenido.wind.speed *3.6;
        var velrafagas2 = contenido.wind.gust *3.6;

        data.push({hora: horario, viento: velviento2, rafagas: velrafagas2})
        }
    })

    var playas = [];

    for( var clave in dirViento){
        if(clave === puntoCardinal)
                playas = dirViento[clave];
    }



    }  
    return(
        <>
            {showData === true ? (
                    <div className="" style={estiloTest}>

                        <ResponsiveContainer  width="100%" aspect={2}>
                        <h4>Vientos y sugerencias </h4>

                            <div  className='datosHoy'>
                                <p>Sector: <span>{puntoCardinal}</span> </p>
                                <p>Velocidad:  <span>{velviento}</span>  Km/h </p>
                                <p>Ráfagas: <span>{velrafagas}</span> Km/h </p>

                            </div>


                            <ComposedChart
                            width={600}
                            height={500}
                            data={data}
                            margin={{
                                top:10,
                                right:30,
                                left:0,
                                bottom:0
                            }}
                            >

                                <CartesianGrid strokeDasharray="3  3" />
                                <XAxis  dataKey="hora" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="rafagas"  barSize={10}  fill="#6dd5ed"  />
                                <Line type="monotone" dataKey="viento" stroke="#8884d8" dot={<CustomizedDot />} />


                            </ComposedChart>  
                            

                            <h5> Playas sugeridas para hoy :  </h5> 
                            <ListGroup>
                            {playas.map((elemento, index) => (
                                <ListGroup.Item key={index}   className='listadoPlayas'>{elemento}</ListGroup.Item>
                                ))}

                            </ListGroup>                    
                        </ResponsiveContainer>

                        
                    </div>

                ):(
                    <h2 className="text-light">Sin datos</h2>
                )
            }
        </>    
    );
}

export default PlanillaVientos;